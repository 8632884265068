import React from 'react';
import { Container, Typography , Grid, CardContent, Card, Box} from '@mui/material';
import card from '../assets/images/card_img.png';
import calender from '../assets/images/calender1.png';
import lock from '../assets/images/lock1.svg';
import notes from '../assets/images/organise.svg';
import men from '../assets/images/men2.svg';
import collab from '../assets/images/collab.svg';
import messages from '../assets/images/messages.svg';

const Benifits = () => {
  return (
    <>
     <section id='faqs'>
     <Container sx={{marginTop:'8%'}}>
     <Typography sx={{fontSize:{xs:'30px', md:'48px'}, fontFamily:'Roboto Slab', color:'#1D3557'}}>
     BENEFITS WITH <span style={{color:'#FF7F11'}}>PREMIUM PLAN</span></Typography>

     {/* cards start from here */}
     <Box sx={{ overflowX: { xs: 'auto', sm: 'auto', md: 'unset' },  }}>
     <Grid container spacing={4} sx={{paddingTop:'4%', flexDirection: { xs: 'row', sm: 'row', md: 'row' },
            flexWrap: { xs: 'nowrap', sm: 'nowrap', md: 'wrap' },
            display: 'flex',}}>

     {/* card1 */}
        <Grid item md={4} >
        <Card sx={{borderRadius:'16px',boxShadow:'3',height: '280px',width:{xs:'63vw', md:"auto"}}}>
            <CardContent>
                <img src={calender} alt=''/>
               
                <Typography sx={{fontSize:'16px', fontFamily:'Roboto Slab', color:'#000000', textAlign:'center'}}>Built-in calendar for managing 
                schedules and appointments.</Typography>
            </CardContent>
        </Card>
        </Grid>


        {/* card2 */}
        <Grid item md={4} >
        <Card sx={{borderRadius:'16px',boxShadow:'3',height: '280px',width:{xs:'63vw', md:"auto"}}}>
            <CardContent>
                <img src={lock} alt=''/>
                <Typography sx={{fontSize:'16px', fontFamily:'Roboto Slab', color:'#000000', textAlign:'center'}}>Easy access to office applications
                (e.g., Word, Excel, PowerPoint).</Typography>
            </CardContent>
        </Card>
        </Grid>

        {/* card3 */}
        <Grid item md={4}>
        <Card sx={{borderRadius:'16px',boxShadow:'3',height: '280px',width:{xs:'63vw', md:"auto"}}}>
            <CardContent>
                <img src={notes} alt=''/>
                <Typography sx={{fontSize:'16px', fontFamily:'Roboto Slab', color:'#000000', textAlign:'center'}}>Organizational tools such as labels,
                filters, categories, and folders.</Typography>
            </CardContent>
        </Card>
        </Grid>

        <Grid item md={4} >
        <Card sx={{borderRadius:'16px',boxShadow:'3',height: '280px',width:{xs:'63vw', md:"auto"}}}>
            <CardContent>
                <img src={men} alt=''/>
                <Typography sx={{fontSize:'16px', fontFamily:'Roboto Slab', color:'#000000', textAlign:'center'}}>Encryption and phishing 
                protection</Typography>
            </CardContent>
        </Card>
        </Grid>


        <Grid item md={4} >
        <Card sx={{borderRadius:'16px',boxShadow:'3',height: '280px',width:{xs:'63vw', md:"auto"}}}>
            <CardContent>
                <img src={collab} alt=''/>
                
                <Typography sx={{fontSize:'16px', fontFamily:'Roboto Slab', color:'#000000', textAlign:'center'}}>Collaboration tools like integrated
                chat and messaging services.</Typography>
            </CardContent>
        </Card>
        </Grid>



        <Grid item md={4} sx={{paddingBottom:'1%'}}>
        <Card sx={{borderRadius:'16px',boxShadow:'3',height: '280px',width:{xs:'63vw', md:"auto"}}}>
            <CardContent>
                <img src={messages} alt=''/>
               
                <Typography sx={{fontSize:'16px', fontFamily:'Roboto Slab', color:'#000000', textAlign:'center'}}>Availability of multiple 
                inboxes</Typography>
            </CardContent>
        </Card>
        </Grid>
     </Grid>
     </Box>
     </Container>   
     </section>
    </>
  )
}

export default Benifits
