import { Container,Grid, Box, Typography ,Button} from '@mui/material'
import React from 'react';
import banner_img from '../assets/images/maskgrou[.png';
import vector from '../assets/images/doubleimg.svg'
import mask from '../assets/images/mask.png'

const About = () => {
  return (
    <>
    <section id='about'>


    <Box sx={{
        marginTop:'8%',
        backgroundImage: `url(${banner_img})`,
        backgroundSize: 'cover', // Makes the background image cover the whole box
        backgroundPosition: 'center', // Centers the background image
        backgroundRepeat: 'no-repeat', // Prevents the image from repeating
        height: '400px', // Adjust the height as needed

    }}>

  
        <Container>
            <Grid container spacing={3}>
            <Grid item xs={12} md={6} sx={{textAlign:{xs:'center', md:'left'}}}>
            <Typography
                sx={{
                    fontFamily: 'Roboto Slab',
                    color: '#000000',
                    fontSize: { xs: '33px', md: '48px' }, // Optionally, set font size to 0px on smaller screens
                    textAlign: { xs: 'center', md: 'left' },
                    display: { xs: 'block', md: 'none' }, // Hide on xs, show on md and up
                }}
                >
                <span style={{ color: '#FF7F11' }}>ABOUT</span> US
                </Typography>
            <Box
                component="img"
                src={vector}
                alt="vector"
                sx={{
                width: { xs: '60%', md: '100%' },
                height: 'auto',
                }}
            />
               
            </Grid>

            <Grid item md={6} sx={{textAlign:{xs:'center', md:'left'}}}>
            <Typography
                sx={{
                    fontFamily: 'Roboto Slab',
                    color: '#000000',
                    fontSize: { xs: '0px', md: '48px' }, // Optionally, set font size to 0px on smaller screens
                    textAlign: { xs: 'center', md: 'left' },
                    display: { xs: 'none', md: 'block' }, // Hide on xs, show on md and up
                }}
                >
                <span style={{ color: '#FF7F11' }}>ABOUT</span> US
                </Typography>

               <Typography sx={{fontFamily:'Roboto Slab', color:'#000000', fontSize:'12px', textAlign:{xs:'center', md:'left'}, paddingTop:'4%'}}>At InboxTree, we’re redefining email for the modern world—making communication seamless, organized, and secure. Our webmail platform is built to keep you effortlessly connected across devices, offering intuitive features like AI-driven spam filtering, customizable inbox layouts, and seamless integration with the tools you already love.</Typography>

               <Typography sx={{fontFamily:'Roboto Slab', color:'#000000', fontSize:'12px', textAlign:{xs:'center', md:'left'}, paddingTop:'4%'}}> We prioritize security and storage, ensuring your emails are protected and easily managed, no matter where life takes you.</Typography> 

               <Typography sx={{fontFamily:'Roboto Slab', color:'#000000', fontSize:'12px',textAlign:{xs:'center', md:'left'},paddingTop:'4%'}}>
        Our mission is simple: to provide a reliable, efficient, and secure email service that empowers both individuals and professionals. Whether you're managing important business communications or staying in touch with family and friends, InboxTree delivers a smarter, streamlined experience. Join us and elevate your email game with InboxTree—where communication meets innovation.
        </Typography>

        <Button sx={{backgroundColor:'#FF7F11', color:'#FFFFFF', borderRadius:'10px 0px 10px 0px', fontSize:'18px', marginTop:'11%',fontFamily:'Roboto Slab', textTransform:'none',padding:'8px 16px'}}>Learn More</Button>
            </Grid>
            </Grid>
        </Container>
        </Box>
        </section>
    </>
  )
}

export default About
