// src/components/CustomLink.js
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const CustomLink = ({ to, smooth, duration, children, ...props }) => {
  // Check if `to` starts with a '/' to determine if it's a route
  const isRoute = to.startsWith('/');

  if (isRoute) {
    return (
      <RouterLink to={to} {...props}>
        {children}
      </RouterLink>
    );
  } else {
    return (
      <ScrollLink to={to} smooth={smooth} duration={duration} {...props}>
        {children}
      </ScrollLink>
    );
  }
};

export default CustomLink;
