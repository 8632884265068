import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Box } from '@mui/material';
import vector from '../assets/images/vector1.png'; // Example image path, adjust as necessary
import email_open from '../assets/images/email_open.png'
import reply from '../assets/images/reply.png'
import undo from '../assets/images/undo.png';
import alarm from '../assets/images/alarm.png'
import inbox from "../assets/images/inbox1.svg"
const Whychoose = () => {
  return (
    <>
      <Container sx={{marginTop:{ md: '15%', xs:'60%', sm:'20%'} }}>
        <Typography
          sx={{
            fontSize: {xs:'33px', md:'48px'},
            fontFamily: 'Roboto Slab',
            color: '#1D3557',
            fontWeight: 300,
            paddingTop:{xs:'39%', md:'0px'}
          }}
        >
          WHY <span style={{ color: '#FF7F11' }}>CHOOSE</span> US
        </Typography>

        <Grid container spacing={4} sx={{ marginTop: '20px' }}>
          {/* First Card */}
          <Grid item md={6}>
            <Card sx={{ height: 'auto', width: '100%', borderRadius: '12px' }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ marginRight: '16px' }}>
                  <img src={inbox} alt="Vector 1" style={{ width: '100px', height: 'auto' }} />
                  {/* <img src={email_open} style={{ position: 'relative', bottom: '63px', left: '1%', zIndex:'9999', height:'31px' }} /> */}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '24px',
                      color: '#000000',
                      fontFamily: 'Roboto Slab',
                      marginBottom: '8px',
                      textAlign:'left'
                    }}
                  >
                    Email Aliases
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: '#000000',
                      fontFamily: 'Roboto Slab',
                      textAlign: 'left',
                    }}
                  >
                    Our custom email aliases offer a convenient solution, allowing you to create multiple unique email addresses that all route to a single inbox.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Second Card */}
          <Grid item md={6}>
            <Card sx={{ height: 'auto', width: '100%', borderRadius: '12px' }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ marginRight: '16px' }}>
                  <img src={vector} alt="Vector 2" style={{ width: '100px', height: 'auto' }} />
                  <img src={reply} style={{ position: 'relative', bottom: '63px', left: '1%', zIndex:'9999' , height:'31px' }}></img>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '24px',
                      color: '#000000',
                      fontFamily: 'Roboto Slab',
                      marginBottom: '8px',
                       textAlign:'left'
                    }}
                  >
                   Smart Reply/Suggested Replies
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: '#000000',
                      fontFamily: 'Roboto Slab',
                      textAlign: 'left',
                    }}
                  >
                    Our Smart Reply and Suggested Replies feature offers quick, relevant response options, making it easier than ever to manage your inbox efficiently.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Third Card */}
          <Grid item md={6}>
            <Card sx={{ height: 'auto', width: '100%', borderRadius: '12px' }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ marginRight: '16px' }}>
                  <img src={vector} alt="Vector 3" style={{ width: '100px', height: 'auto' }} />
                  <img src={undo} style={{ position: 'relative', bottom: '63px', left: '1%', zIndex:'9999' , height:'31px' }}></img>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '24px',
                      color: '#000000',
                      fontFamily: 'Roboto Slab',
                      marginBottom: '8px',
                       textAlign:'left'
                    }}
                  >
                    Undo Send
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: '#000000',
                      fontFamily: 'Roboto Slab',
                      textAlign: 'left',
                    }}
                  >
                    Have you ever wished you could take back an email you just sent? With our new "Undo Send" feature, now you can! 
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Fourth Card */}
          <Grid item md={6}>
            <Card sx={{ height: 'auto', width: '100%', borderRadius: '12px' }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ marginRight: '16px' }}>
                  <img src={vector} alt="Vector 4" style={{ width: '100px', height: 'auto' }} />
                  <img src={alarm} style={{ position: 'relative', bottom: '63px', left: '1%', zIndex:'9999' , height:'31px' }}></img>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '24px',
                      color: '#000000',
                      fontFamily: 'Roboto Slab',
                      marginBottom: '8px',
                       textAlign:'left'
                    }}
                  >
                    Priority Notifications
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: '#000000',
                      fontFamily: 'Roboto Slab',
                      textAlign: 'left',
                    }}
                  >
                   Our Priority Notifications feature ensures you never miss a critical message again by highlighting and alerting you to your most important emails.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Whychoose;
