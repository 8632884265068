import React from 'react';
import {Container, Typography,Grid, Box} from '@mui/material';
import how from '../assets/images/howto.png';
import line from '../assets/images/line1.svg'

const How = () => {
  return (
    <div>
    <section id='how'>

  
      <Container sx={{marginTop:'7%'}}>
      <Grid container>
        {/* <Grid item xs={12} md={5} sx={{textAlign:''}}>
            <Typography sx={{fontSize:{xs:'30px', md:'100px'}, fontFamily:'Roboto Slab', color:'#FF7F11', textAlign:{xs:'center', md:'left'}}}>How</Typography>
            <Typography sx={{fontSize:{xs:'30px', md:'100px'}, fontFamily:'Roboto Slab', color:'#FF7F11',textAlign:{xs:'center', md:'left', lineHeight:'68px'}}}><span style={{color:'#1D3557',}}>TO</span> USE</Typography>
          
           <img src={how}  style={{width:'100%'}}>
       
           </img>
           
        </Grid> */}
        <Grid item xs={12} md={5} sx={{ textAlign: '' }}>
    <Box 
        sx={{
            display: { xs: 'flex', md: 'block' }, 
            flexDirection: { xs: 'row', md: 'column' }, 
            alignItems: { xs: 'center', md: 'flex-start' }, 
            justifyContent: { xs: 'center', md: 'flex-start' }, 
            gap: 1 
        }}
    >
        <Typography 
            sx={{
                fontSize: { xs: '33px', md: '100px' }, 
                fontFamily: 'Roboto Slab', 
                color: '#FF7F11', 
                textAlign: { xs: 'center', md: 'left' }
            }}
        >
            HOW
        </Typography>
        <Typography 
            sx={{
                fontSize: { xs: '33px', md: '100px' }, 
                fontFamily: 'Roboto Slab', 
                color: '#FF7F11', 
                textAlign: { xs: 'center', md: 'left' },
                lineHeight: '68px'
            }}
        >
            <span style={{ color: '#1D3557' }}>TO</span> USE
        </Typography>
    </Box>
    <img src={how} style={{ width: '100%' }} alt="How to use" />
</Grid>


        <Grid item xs={2} md={1} sx={{paddingTop:'5%'}}>
        <img src={line} alt='line' style={{height:'670px'}} />
        </Grid>
       
        <Grid item xs={10} md={6}>
            <Grid container spacing={0}>
                <Grid item md={12} sx={{textAlign:'left', marginTop:'10%'}}>
                    <Typography sx={{color:'#FF7F11', fontSize:{xs:'22px',md:'33px'}, fontFamily:'Roboto Slab'}}>Find the Right Plan for You</Typography>
                    <Typography  sx={{color:'#000000', fontSize:{xs:'14px',md:'18px'}, fontFamily:'Roboto Slab', fontWeight:300}}>Explore our variety of plans, crafted for everyone from solo users to large teams. Each plan is packed with unique features designed to enhance your email experience. Choose the one that fits your needs and unlock the best of InboxTree.</Typography>
                </Grid>

                <Grid item md={12} sx={{textAlign:'left', marginTop:'3%'}}>
                    <Typography sx={{color:'#FF7F11', fontSize:{xs:'22px',md:'33px'}, fontFamily:'Roboto Slab'}}>Safe and Simple Checkout</Typography>
                    <Typography  sx={{color:'#000000', fontSize:{xs:'14px',md:'18px'}, fontFamily:'Roboto Slab', fontWeight:300}}>Complete your purchase securely and effortlessly with our streamlined payment process. We offer multiple payment options for your convenience. Once you're done, you’ll have instant access to InboxTree’s full suite of features.</Typography>
                </Grid>

                <Grid item md={12} sx={{textAlign:'left', marginTop:'6%'}}>
                    <Typography sx={{color:'#FF7F11', fontSize:{xs:'22px',md:'33px'}, fontFamily:'Roboto Slab'}}>Access the Best of InboxTree</Typography>
                    <Typography  sx={{color:'#000000', fontSize:{xs:'14px',md:'18px'}, fontFamily:'Roboto Slab', fontWeight:300}}>Complete your purchase securely and effortlessly with our streamlined payment process. We offer multiple payment options for your convenience. Once you're done, you’ll have instant access to InboxTree’s full suite of features..</Typography>
                </Grid>
            </Grid>
        </Grid>
      </Grid>
      </Container>
      </section>
    </div>
  )
}

export default How
