import './App.css';
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Features from './components/Features';
import About from './components/About';
import Whychoose from './components/Whychoose';
import How from './components/How';
import Pricing1 from './components/Pricing/Pricing1';
import Benifits from './components/Benifits';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Invoices from './components/Invoices/Invoices';
import InvoiceDetails from './components/Invoices/InvoiceDetails';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={
            <>
              
              <Banner />
              <Features />
              <About />
              <Whychoose />
              <How />
              <Pricing1 />
              <Benifits />
              <Contact />
              <Footer />
            </>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/invoiceDetails/:id" element={<InvoiceDetails />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
