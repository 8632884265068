import React from 'react';
import Navbar from '../components/Navbar'; 
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Container, Grid, Typography, Button , Box} from '@mui/material';
import banner_img2 from '../assets/images/banner_img2.png';
import banner_img3 from '../assets/images/banner_img3.png';
import banner_back from '../assets/images/banner_back.png';
import Access from '../assets/images/Access.png';
import CustomLink from './CustomLink';
import { Link } from 'react-scroll';

const Banner = () => {
  return (
    <>
    
     <AppBar position="static" sx={{ backgroundColor: '#1D3557'}}>
     <Container>
  
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', md: 'flex-end' }}}>
      {/* Image */}
      <img
        src={Access}
        alt="img"
        style={{
          paddingRight: '8px',
          display: { xs: 'block', md: 'block' }, // Ensure image shows on all screens
        }}
      />
      {/* Sign In */}
      <CustomLink to="/login">
        <Typography
          sx={{
            color: '#FFFFFF',
            fontSize: '10px',
            cursor: 'pointer',
            fontFamily: 'Roboto Slab',
            textAlign: 'center', // Center text
          }}
        >
          Sign In
        </Typography>
      </CustomLink>
    </Box>
  </Container>
</AppBar>
 <Box sx={{
  backgroundImage: `url(${banner_back})`,
    backgroundSize: 'cover', // Adjusts the image to cover the entire box
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Ensures the image doesn't repeat
    
 }}>

<Navbar />

      <Container>
        <Grid container>
          {/* Left Section for Larger Screens, Right Section for Smaller Screens */}
          <Grid item md={6} xs={12} 
          sx={{ textAlign: {xs:'center', md:'left'},
           paddingTop: { xs: '2%', md: '6%'}, order: { xs: 2, md: 1 } }}>
            <Typography sx={{ fontFamily: 'Roboto', color: '#1D3557', fontSize: {xs:'33px', md:'54px'}, lineHeight: 'auto' , textAlign:{xs:'center', md:'left'}}}>
              <span style={{ fontFamily: 'Roboto Slab', color: '#FF7F11' }}>Take Control</span> of Your Inbox with{' '}
              <span style={{ fontFamily: 'Roboto Slab', color: '#FF7F11' }}>InboxTree</span>
            </Typography>
            <img src={banner_img3}/>
            <Typography
              sx={{
                fontFamily: 'Roboto Slab',
                color: '#000000',
                fontSize: '16px',
                lineHeight: '30px',
                paddingTop: '2%',
                textAlign:{xs:'center', md:'left'}
              }}
            >
              Discover the freedom of unlimited email access with InboxTree—your go-to platform for managing all your communication needs, wherever you are. 
              Our advanced security features, including two-factor authentication and powerful spam filters, ensure your emails stay safe and secure. 
              With generous storage and easy integration with calendars and cloud storage, InboxTree helps you stay organized and efficient.
            </Typography>

            <Link to='pricing' smooth={true} duration={500}><Button
              sx={{
                backgroundColor: '#FF7F11',
                color: '#FFFFFF',
                borderRadius: '10px 0px 10px 0px',
                fontSize: '18px',
                marginTop: '5%',
                fontFamily: 'Roboto Slab',
                textTransform: 'none',
              }}
            >
              Get Your Email
            </Button></Link>
          </Grid>

          {/* Right Section (Image) */}
          <Grid item md={6} xs={12} sx={{ display: { xs: 'flex', md: 'block' }, justifyContent: 'center', paddingTop: '2%' ,  order: { xs: 1, md: 2 }}}>
            <img src={banner_img2} style={{ width: '573.98px', maxWidth: '100%' }} alt="Banner" />
          </Grid>
        </Grid>
      </Container>
      </Box>
    </>
  );
};

export default Banner;
