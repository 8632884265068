import { Container, Typography , Grid , Card , CardContent, Box} from '@mui/material';
import React from 'react';
import card_img from '../assets/images/card_img.png';
import stack from '../assets/images/inbox1.svg';
import spam from '../assets/images/spam.svg';
import search from '../assets/images/search.svg';
import lens from '../assets/images/ai.svg';
import inbox from '../assets/images/customize.svg';
import outlook from '../assets/images/email_open1.svg';
const Features = () => {
  return (
    <>
     <section id='features'>
    <Container sx={{marginTop:{xs:'7%', md:'3%'}}}>
        <Typography sx={{
            fontFamily:'Roboto Slab',
            color:'#1D3557',
            fontSize:{xs:'30px', md:'48px'},
            lineHeight:{xs:'',md:'30px'},
            fontWeight:300
           
        }}>LATEST <span style={{color:'#FF7F11', }}>FEATURES</span></Typography>


        {/* CARDS START  */}
        <Box sx={{ overflowX: { xs: 'auto', sm: 'auto', md: 'unset' },  }}>
        <Grid container 
        sx={{
            paddingTop:'4%',
            flexDirection: { xs: 'row', sm: 'row', md: 'row' },
            flexWrap: { xs: 'nowrap', sm: 'nowrap', md: 'wrap' },
            display: 'flex',
            paddingBottom:{xs:'2%'}

            }} 
        spacing={7}>

            <Grid item md={4} >
            <Card sx={{ height: {xs:'380px', md:'420px'},width:{xs:'63vw', md:"auto"}, borderRadius:'18px',boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)', }}>
            <CardContent>
            <img src={stack}  style={{}}></img>
           
            <Typography sx={{
                color:'#FF7F11',
                fontFamily:'Roboto Slab',
                fontSize:'20px'
            }}>
            Generous Storage
            </Typography>
            <Typography sx={{
                color:'#000000',
                fontFamily:'Roboto Slab',
                fontSize:'16px',
                textAlign:'center'
            }}>
            Are you tired of constantly running out of storage space? We have the perfect solution for you and we provide best storage space! 
            </Typography>
            </CardContent>
            </Card>
            </Grid>

            <Grid item md={4} >
            <Card sx={{ height: {xs:'380px', md:'420px'},width:{xs:'63vw', md:"auto"}, borderRadius:'18px',boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)', }}>
            <CardContent>
            <img src={spam}  style={{}}></img>
           
            <Typography sx={{
                color:'#FF7F11',
                fontFamily:'Roboto Slab',
                fontSize:'20px'
            }}>
           Advanced spam filtering
            </Typography>
            <Typography sx={{
                color:'#000000',
                fontFamily:'Roboto Slab',
                fontSize:'16px',
                textAlign:'center'
            }}>
            Our advanced spam filtering system offers state-of-the-art protection, ensuring you receive only the emails that matter.
            </Typography>
            </CardContent>
            </Card>
            </Grid>

            <Grid item md={4} >
            <Card sx={{ height: {xs:'380px', md:'420px'},width:{xs:'63vw', md:"auto"}, borderRadius:'18px',boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)', }}>
            <CardContent>
            <img src={search}  style={{}}></img>
            <Typography sx={{
                color:'#FF7F11',
                fontFamily:'Roboto Slab',
                fontSize:'20px'
            }}>
           Powerful search capabilities.
            </Typography>
            <Typography sx={{
                color:'#000000',
                fontFamily:'Roboto Slab',
                fontSize:'16px',
                textAlign:'center'
            }}>
            Our powerful search capabilities are designed to simplify your email management, helping you find critical messages quickly and efficiently.
            </Typography>
            </CardContent>
            </Card>
            </Grid>


            <Grid item md={4}>
            <Card sx={{ height: {xs:'380px', md:'420px'},width:{xs:'63vw', md:"auto"}, borderRadius:'18px',boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)', }}>
            <CardContent>
            <img src={lens}  style={{}}></img>
           
            <Typography sx={{
                color:'#FF7F11',
                fontFamily:'Roboto Slab',
                fontSize:'20px'
            }}>
          AI-driven threat detection and
          security measures.
            </Typography>
            <Typography sx={{
                color:'#000000',
                fontFamily:'Roboto Slab',
                fontSize:'16px',
                textAlign:'center'
            }}>
           Our advanced AI-driven threat detection 
            system offers robust protection to safeguard 
            your sensitive information and 
            communications.
            </Typography>
            </CardContent>
            </Card>
            </Grid>


            <Grid item md={4} >
            <Card sx={{height: {xs:'380px', md:'420px'},width:{xs:'63vw', md:"auto"}, borderRadius:'18px',boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',paddingBottom:'2%' }}>
            <CardContent>
            <img src={inbox}  style={{}}></img>
            
            <Typography sx={{
                color:'#FF7F11',
                fontFamily:'Roboto Slab',
                fontSize:'20px'
            }}>
           Customizable inbox themes 
           and layouts
            </Typography>
            <Typography sx={{
                color:'#000000',
                fontFamily:'Roboto Slab',
                fontSize:'16px',
                textAlign:'center'
            }}>
           Our customizable inbox themes and layouts empower you to create a personalized email experience that enhances both functionality and aesthetics.
            </Typography>
            </CardContent>
            </Card>
            </Grid>


            <Grid item md={4} >
            <Card sx={{ height: {xs:'380px', md:'420px'},width:{xs:'63vw', md:"auto"}, borderRadius:'18px',boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)', }}>
            <CardContent>
            <img src={outlook} style={{}}></img>
            
            <Typography sx={{
                color:'#FF7F11',
                fontFamily:'Roboto Slab',
                fontSize:'20px'
            }}>
          Focused inbox prioritizing 
          important emails
            </Typography>
            <Typography sx={{
                color:'#000000',
                fontFamily:'Roboto Slab',
                fontSize:'16px',
                textAlign:'center'
            }}>
           Our focused inbox solution prioritizes your most crucial messages, helping you stay organized and productive.
            </Typography>
            </CardContent>
            </Card>
            </Grid>
        </Grid>
        </Box>
        

    </Container>
    </section>
    </>
  )
}

export default Features
