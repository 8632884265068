import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import logo from '../assets/images/Logo2.svg';
import { Container, Grid, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Login_Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };


  const handleMenuClose =()=> {
    setDrawerOpen(false)
  }

  return (
    <Container>
      <Grid container sx={{ paddingTop: '2%' }}>
        <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}>
          <img src={logo} alt='drift mail' />
        </Grid>
        <Grid item md={6} xs={6}>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', alignItems: 'center', paddingTop: '1%', gap: '20px' }}>
            <Link to='/' style={{textDecoration:'none'}}>
              <Typography sx={{ color: '#1D3557', fontSize: '15px', fontFamily: 'Roboto Slab', fontWeight: 300, '&:hover': { color: '#FF7F11', cursor: 'pointer' } }}>Home</Typography>
            </Link>
            <a href='/#features' style={{textDecoration:'none'}}>
              <Typography sx={{ color: '#1D3557', fontSize: '15px', fontFamily: 'Roboto Slab', fontWeight: 300, '&:hover': { color: '#FF7F11', cursor: 'pointer' } }}>Features</Typography>
            </a>
            <a href='/#about' style={{textDecoration:'none'}}>
              <Typography sx={{ color: '#1D3557', fontSize: '15px', fontFamily: 'Roboto Slab', fontWeight: 300, '&:hover': { color: '#FF7F11', cursor: 'pointer' } }}>About us</Typography>
            </a>
            <a href='/#how' style={{textDecoration:'none'}}>
              <Typography sx={{ color: '#1D3557', fontSize: '15px', fontFamily: 'Roboto Slab', fontWeight: 300, '&:hover': { color: '#FF7F11', cursor: 'pointer' } }}>How to use</Typography>
            </a>
            <a href='/#pricing' style={{textDecoration:'none'}}>
              <Typography sx={{ color: '#1D3557', fontSize: '15px', fontFamily: 'Roboto Slab', fontWeight: 300, '&:hover': { color: '#FF7F11', cursor: 'pointer' } }}>Pricing</Typography>
            </a>
            {/* <a href='/#faqs' style={{textDecoration:'none'}}>
              <Typography sx={{ color: '#1D3557', fontSize: '15px', fontFamily: 'Roboto Slab', fontWeight: 300, '&:hover': { color: '#FF7F11', cursor: 'pointer' } }}>FAQ's</Typography>
            </a> */}
          </Box>

          {/* Menu Icon for smaller screens */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
            <IconButton
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ color: '#1D3557', fontSize: '40px' }}
            >
              <MenuIcon sx={{ fontSize: '40px' }} />
            </IconButton>
            <Drawer
          sx={{   paddingTop: '20px',}}
          anchor="top"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
       
          ModalProps={{
            keepMounted: true,
            
          }}
          PaperProps={{
            sx: {
              height:'40%',
              width: '90%',
              maxWidth: '500px',
              margin: 'auto',
              transform: 'translateY(20%)',
              borderRadius: 2,
              boxShadow: 3,
              backdropFilter: 'blur(6px)',
           
              
              
            },
          }}
        >
  <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 1 }}>
    <IconButton onClick={toggleDrawer(false)}>
      <CloseIcon sx={{ color: '#1D3557', fontSize: '30px' }} />
    </IconButton>
  </Box>
  <List>
    {[
      { text: 'Home', to: 'home' },
      { text: 'Features', to: 'features' },
      { text: 'About us', to: 'about' },
      { text: 'How to use', to: 'how' },
      { text: 'Pricing', to: 'pricing' },
      { text: "FAQ's", to: 'faqs' }
    ].map((item) => (
      <ListItem button key={item.text} onClick={toggleDrawer(false)}>
        <Link to={item.to} smooth={true} duration={500} onClick={handleMenuClose}>
          <ListItemText 
            primary={item.text} 
            sx={{ textAlign: 'center', color: '#FF7F11', fontFamily: 'Roboto Slab', fontWeight: 300, fontSize: '18px', cursor:'pointer' }} 
          />
        </Link>
      </ListItem>
    ))}
  </List>
</Drawer>


          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login_Navbar;
